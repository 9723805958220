import * as React from 'react'
import { memo } from 'react'

const SvgRumble = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    viewBox="0 0 28.211 28.211"
  >
    <path d="M14.105 28.211a16.9 16.9 0 0 0-14.1-14.1A16.906 16.906 0 0 0 14.105.004a16.908 16.908 0 0 0 14.106 14.098 16.9 16.9 0 0 0-14.106 14.1" />
  </svg>
)

const Memo = memo(SvgRumble)
export default Memo

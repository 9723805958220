import * as React from 'react'
import { memo } from 'react'

const SvgGift = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    viewBox="0 0 21.5 21.5"
  >
    <path d="M18.75 10.75v10h-16v-10M.75 5.75h20v5h-20zM10.75 20.75v-15M10.75 5.75h-4.5a2.5 2.5 0 0 1 0-5c3.5 0 4.5 5 4.5 5ZM10.75 5.75h4.5a2.5 2.5 0 0 0 0-5c-3.5 0-4.5 5-4.5 5Z" />
  </svg>
)

const Memo = memo(SvgGift)
export default Memo

import * as React from 'react'
import { memo } from 'react'

const SvgSpa = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    viewBox="0 0 25.401 20.4"
  >
    <path d="M24.775 7.871a11.919 11.919 0 0 0-4.341.649 12.373 12.373 0 0 0-.882-4.364.439.439 0 0 0-.574-.248 12.043 12.043 0 0 0-2.445 1.351A19.275 19.275 0 0 0 13.012.331a.436.436 0 0 0-.623 0 19.3 19.3 0 0 0-3.521 4.927 12.035 12.035 0 0 0-2.444-1.351.439.439 0 0 0-.574.248 12.374 12.374 0 0 0-.882 4.372 11.867 11.867 0 0 0-4.341-.656.445.445 0 0 0-.425.463A12.2 12.2 0 0 0 12.288 20.2c.137 0 .275 0 .412-.007s.275.007.412.007A12.2 12.2 0 0 0 25.197 8.334a.445.445 0 0 0-.425-.463ZM6.506 4.918a11.1 11.1 0 0 1 3.315 2.324 11.347 11.347 0 0 1 2.4 3.633 12.416 12.416 0 0 0-.741 3.063 12.248 12.248 0 0 0-5.636-5.065 11.443 11.443 0 0 1 .658-3.956Zm-5.4 3.842a11.043 11.043 0 0 1 7.592 3.062 11.39 11.39 0 0 1 3.536 7.482A11.309 11.309 0 0 1 1.107 8.76Zm9.338-2.151q-.415-.422-.865-.8a18.378 18.378 0 0 1 3.118-4.519 18.377 18.377 0 0 1 3.12 4.522q-.449.376-.864.8a12.2 12.2 0 0 0-2.256 3.175 12.2 12.2 0 0 0-2.255-3.175Zm2.253 10.227a12.325 12.325 0 0 0-.405-1.216v-.308a11.514 11.514 0 0 1 .82-4.274 11.361 11.361 0 0 1 2.47-3.8 11.114 11.114 0 0 1 3.315-2.319 11.443 11.443 0 0 1 .658 3.947 12.093 12.093 0 0 0-3.453 2.3 12.25 12.25 0 0 0-3.4 5.669Zm.467 2.468a11.39 11.39 0 0 1 3.536-7.482 11.044 11.044 0 0 1 7.597-3.062 11.308 11.308 0 0 1-11.129 10.545Z" />
  </svg>
)

const Memo = memo(SvgSpa)
export default Memo

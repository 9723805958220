import * as React from 'react'
import { memo } from 'react'

const SvgChevronDown = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    viewBox="0 0 10.121 5.811"
  >
    <path d="m9.061 1.061-4 4-4-4" />
  </svg>
)

const Memo = memo(SvgChevronDown)
export default Memo

import * as React from 'react'
import { memo } from 'react'

const SvgEmail = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    viewBox="0 0 22.078 17.5"
  >
    <path d="M3.039.75h16a2.006 2.006 0 0 1 2 2v12a2.006 2.006 0 0 1-2 2h-16a2.006 2.006 0 0 1-2-2v-12a2.006 2.006 0 0 1 2-2Z" />
    <path d="m21.039 2.633-10 6.589-10-6.589" />
  </svg>
)

const Memo = memo(SvgEmail)
export default Memo

import * as React from 'react'
import { memo } from 'react'

const SvgCheck = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    viewBox="0 0 16 12.144"
  >
    <path d="M14.298.001a1.654 1.654 0 0 0-1.137.5c-2.617 2.623-5.006 5.194-7.519 7.76L2.688 5.892A1.655 1.655 0 0 0 .621 8.476l4.134 3.307a1.654 1.654 0 0 0 2.2-.129c2.974-2.98 5.638-5.907 8.544-8.82A1.654 1.654 0 0 0 14.293 0Z" />
  </svg>
)

const Memo = memo(SvgCheck)
export default Memo

import * as React from 'react'
import { memo } from 'react'

const SvgCalendar = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    viewBox="0 0 18 19"
  >
    <g transform="translate(-2.5 -1.5)">
      <rect width={17} height={16} rx={2} transform="translate(3 4)" />
      <path d="M15 2v4M8 2v4M3 10h17" />
    </g>
  </svg>
)

const Memo = memo(SvgCalendar)
export default Memo

import * as React from 'react'
import { memo } from 'react'

const SvgMail = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    viewBox="0 0 21.385 17"
  >
    <path d="M2.693.5h16a2.006 2.006 0 0 1 2 2v12a2.006 2.006 0 0 1-2 2h-16a2.006 2.006 0 0 1-2-2v-12a2.006 2.006 0 0 1 2-2Z" />
    <path d="m20.693 2.383-10 6.589-10-6.589" />
  </svg>
)

const Memo = memo(SvgMail)
export default Memo

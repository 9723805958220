import * as React from 'react'
import { memo } from 'react'

const SvgWhatsapp = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    viewBox="0 0 20 20"
  >
    <path d="M10 19.999a9.9 9.9 0 0 1-5.484-1.652L.672 19.572l1.25-3.713A9.884 9.884 0 0 1 0 9.999a10.018 10.018 0 0 1 10-10 10.008 10.008 0 0 1 10 10 10 10 0 0 1-10 10ZM6.234 4.573a1.393 1.393 0 0 0-1.016.357l-.016.027a3.19 3.19 0 0 0-1 2.4 5.6 5.6 0 0 0 1.172 3.01l.016.008c0 .014.031.039.047.076a12.068 12.068 0 0 0 4.922 4.32 8.542 8.542 0 0 0 2.922.822 2.461 2.461 0 0 0 .594-.07 2.792 2.792 0 0 0 1.953-1.41A2.33 2.33 0 0 0 16 12.728a1 1 0 0 0-.391-.264c-.016-.01-.062-.021-.078-.033a.52.52 0 0 0-.094-.047c-.234-.119-1.7-.844-2-.943a.676.676 0 0 0-.25-.053.55.55 0 0 0-.453.27l-.125.172a9.025 9.025 0 0 1-.656.861.551.551 0 0 1-.422.17.681.681 0 0 1-.281-.055.292.292 0 0 0-.094-.035l-.016-.014a7.248 7.248 0 0 1-2.234-1.4 8.254 8.254 0 0 1-1.609-2.016.436.436 0 0 1 .094-.615l.016-.01a2.4 2.4 0 0 1 .25-.295l.016-.01a1.992 1.992 0 0 0 .156-.174l.047-.041v-.018a1.3 1.3 0 0 0 .266-.4.559.559 0 0 0-.031-.543c-.062-.111-.406-.963-.609-1.459-.078-.191-.156-.373-.2-.525l-.094-.178c-.187-.461-.328-.484-.625-.5h-.156c-.052.003-.114.001-.193.001Z" />
  </svg>
)

const Memo = memo(SvgWhatsapp)
export default Memo

import * as React from 'react'
import { memo } from 'react'

const SvgMq = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    viewBox="0 0 24.009 24.009"
  >
    <path d="m6.139 1.009-.13 15a2 2 0 0 0 2 2h15" />
    <path d="m1.009 6.139 15-.13a2 2 0 0 1 2 2v15" />
  </svg>
)

const Memo = memo(SvgMq)
export default Memo

import * as React from 'react'
import { memo } from 'react'

const SvgMaximize = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    viewBox="0 0 42 42"
  >
    <path d="M12.111 1H5.444A4.444 4.444 0 0 0 1 5.444v6.667m40 0V5.444A4.444 4.444 0 0 0 36.556 1h-6.667m0 40h6.667A4.444 4.444 0 0 0 41 36.556v-6.667m-40 0v6.667A4.444 4.444 0 0 0 5.444 41h6.667" />
  </svg>
)

const Memo = memo(SvgMaximize)
export default Memo

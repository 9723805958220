import * as React from 'react'
import { memo } from 'react'

const SvgHand = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    viewBox="0 0 28.071 36"
  >
    <path d="M25.071 12.753a2.949 2.949 0 0 0-1.68.517 3 3 0 0 0-2.82-2.017 2.949 2.949 0 0 0-1.68.518 3 3 0 0 0-2.82-2.017 2.932 2.932 0 0 0-1.5.42V3.005a3 3 0 1 0-6 .127v17.375L6.516 18.16a3.75 3.75 0 0 0-5.144-.457l-.525.42a2.19 2.19 0 0 0-.525 2.88l5.347 8.624a12.261 12.261 0 0 0 10.4 6.374 12 12 0 0 0 12-12v-8.248a3 3 0 0 0-3-3Zm-9 21.746A10.767 10.767 0 0 1 6.96 28.86l-5.332-8.668a.7.7 0 0 1 .157-.9l.525-.42a2.249 2.249 0 0 1 3.082.278l3.367 3.847a.75.75 0 0 0 1.312-.495V3.117a1.589 1.589 0 0 1 1.185-1.612 1.5 1.5 0 0 1 1.815 1.5v15a.75.75 0 1 0 1.5 0v-5.252a1.5 1.5 0 1 1 3 0v5.249a.75.75 0 0 0 1.5 0v-3.75a1.5 1.5 0 0 1 3 0v3.75a.75.75 0 1 0 1.5 0v-2.25a1.5 1.5 0 1 1 3 0v8.249a10.5 10.5 0 0 1-10.5 10.5Z" />
  </svg>
)

const Memo = memo(SvgHand)
export default Memo

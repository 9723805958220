import * as React from 'react'
import { memo } from 'react'

const SvgUsers = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    viewBox="0 0 24 20.087"
  >
    <g transform="translate(0 -1.913)">
      <path d="M17 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2" />
      <circle cx={4} cy={4} r={4} transform="translate(5 3)" />
      <path d="M23 21v-2a4 4 0 0 0-3-3.87M16 3.13a4 4 0 0 1 0 7.75" />
    </g>
  </svg>
)

const Memo = memo(SvgUsers)
export default Memo

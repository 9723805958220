import * as React from 'react'
import { memo } from 'react'

const SvgDownload = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    viewBox="0 0 20.038 20"
  >
    <path d="M.953 20a.949.949 0 0 1-.94-.846.748.748 0 0 1-.013-.17v-4.436a.947.947 0 0 1 1.893 0V18.1h16.258v-3.552a.946.946 0 0 1 1.615-.669 1 1 0 0 1 .271.675v4.436a1.009 1.009 0 0 1-.031.246.952.952 0 0 1-.927.764Zm8.959-4.9c-.019 0-.038-.006-.057-.006a1.076 1.076 0 0 1-.612-.309l-.025-.025-4.65-4.65a1.106 1.106 0 0 1 1.565-1.564l2.795 2.795V1.1a1.109 1.109 0 0 1 1.1-1.1 1.126 1.126 0 0 1 .782.322 1.088 1.088 0 0 1 .322.782v10.239l2.8-2.795a1.112 1.112 0 0 1 1.565 0 1.13 1.13 0 0 1-.019 1.564l-4.65 4.65c-.006.013-.019.019-.025.025a1.1 1.1 0 0 1-.612.309c-.019 0-.038.006-.057.006a.634.634 0 0 1-.114.007Z" />
  </svg>
)

const Memo = memo(SvgDownload)
export default Memo
